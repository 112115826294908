import React from 'react';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';
import ProjectCard from './ProjectCard';
import { softwareProjects, musicProjects } from './ProjectData';

export default function Projects() {
    React.useEffect(() => window.scrollTo(0,0), []) // go to top on first load
    function layOutProjectCards(proj, idx) {
        let yArray = new Array(idx + 2).fill(-100)
        yArray[yArray.length - 1] = 0
        let opacityArray = new Array(idx + 2).fill(0)
        opacityArray[opacityArray.length] = 1
        return <div key={idx} className="col-sm-6 col-md-4 col-lg-3">
            <motion.div
                animate={{ y: yArray, opacity: opacityArray }}
                transition={{ duration: 0.3 * (idx + 1), ease: "linear" }}>
                <ProjectCard
                    imgSrc={proj.imgSrc}
                    title={proj.title}
                    date={proj.date}
                    blurb={proj.blurb}
                    description={proj.description}
                    links={proj.links}
                    modalId={proj.modalId} />
            </motion.div>
        </div>
    }

    return (
        <section>
            {/* Side navigation links */}
            <div id="projects-side-nav" className="fs-4 text-end">
                <HashLink
                    to="/projects#software"
                    id="software-link"
                    className="side-nav-link p-3">
                    <i className="bi bi-code-slash"></i>
                    <span className="px-4">Software</span>
                    <i className="bi bi-code-slash"></i>
                    <i className="bi bi-caret-right-fill"></i>
                </HashLink>
                <HashLink to="/projects#music" id="music-link" className="side-nav-link p-3">
                    <i className="bi bi-soundwave"></i>
                    <span className="px-4">Music</span>
                    <i className="bi bi-soundwave"></i>
                    <i className="bi bi-caret-right-fill"></i>
                </HashLink>
            </div>

            {/* Software Projects Section */}
            <section id="software-projects" className="px-5 pb-5 bg-dark">
                <span id="software" style={{ position: 'relative', top: '-150px' }}></span>
                <div className="container">
                    <motion.div
                        animate={{ x: [-100, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5, ease: "linear" }}>        
                        <h1 className="text-center text-white">Software Projects</h1>
                        <hr className="bg-light" />
                    </motion.div>
                    <motion.div
                        animate={{ opacity: [0, 0, 0, 1] }}
                        transition={{ duration: 1, ease: "linear" }}>        
                        <div className="row g-4 justify-content-center">
                        {/* replace the line above with the one below for horizontal scrilling */}
                        {/* <div className="row g-4 custom-scrollbar flex-nowrap overflow-y-hidden pb-3"> */}
                                {softwareProjects.map(layOutProjectCards)}
                        </div>
                    </motion.div>
                </div>
            </section>

            {/* Music Projects Section */}
            <section id="music-projects" className="p-5 bg-secondary">
                <span id="music" style={{ position: 'relative', top: '-150px' }}></span>
                <div className="container">
                    <motion.div
                        animate={{ x: [-100, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5, ease: "linear" }}>        
                        <h1 className="text-center text-white">Music Projects</h1>
                        <hr className="bg-light" />
                    </motion.div>
                    <motion.div
                        animate={{ opacity: [0, 0, 0, 1] }}
                        transition={{ duration: 1, ease: "linear" }}>        
                        <div className="row g-4 justify-content-center">
                        {/* replace the line above with the one below for horizontal scrilling */}
                        {/* <div className="row g-4 custom-scrollbar flex-nowrap overflow-y-hidden pb-3"> */}
                                {musicProjects.map(layOutProjectCards)}
                        </div>
                    </motion.div>
                </div>
            </section>
        </section>
    )
}
