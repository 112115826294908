import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    // hack to get to the right route despite it being an SPA served statically.
    const navigate = useNavigate()
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const path = urlParams.get('path')
        if (path) {
            setTimeout(() => {
                navigate(path)
            })
        }
    }, [])

    React.useEffect(() => window.scrollTo(0, 0), []) // go to top on first load
    return (
        <>
            {/* Welcome Section */}
            <section className="bg-dark text-light p-lg-0 text-center text-sm-start">
                <div className="container px-5">
                    <div className="d-sm-flex align-items-center justify-content-between">
                        <div>
                            <motion.div
                                animate={{ x: [-100, 0], opacity: [0, 1] }}
                                transition={{ duration: 1 }}
                            >
                                <h1>Hey there!</h1>
                            </motion.div>
                            <motion.div
                                animate={{ x: [-100, -100, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 2.5 }}
                            >
                                <h1><span className="text-warning">Let's build something great.</span></h1>
                            </motion.div>
                            <motion.div
                                animate={{ opacity: [0, 0, 0, 1] }}
                                transition={{ duration: 4 }}
                            >
                                <p className="lead my-4">
                                    I'm Ophir, an Israeli-American software engineer.
                                </p>
                            </motion.div>
                            <motion.div
                                animate={{ opacity: [0, 0, 0, 0, 1] }}
                                transition={{ duration: 5 }}
                            >
                                <a className="btn btn-outline-light btn-lg mb-4" data-bs-toggle="modal" data-bs-target="#about">
                                    More about me.
                                </a>
                            </motion.div>
                        </div>
                        <img className="img-fluid w-50 d-none d-sm-block" src="assets/coder.svg" />
                    </div>
                </div>
            </section>

            {/* "More about me." Modal */}
            <div className="modal fade" id="about" tabIndex="-1" aria-labelledby="aboutLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="aboutLabel">More About Me</h5>
                            <button type="button" className="btn-close btn-close-white shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body my-2 me-2 custom-scrollbar">
                            <p className="d-flex justify-content-center">
                                <img className="w-50 bg-secondary rounded-circle my-4" src="assets/pro_portrait.jpeg" style={{ minWidth: '300px' }} alt="" />
                            </p>
                            <p className="lead mb-0" style={{ 'maxHeight': '35vh' }}>
                                I hold a BSc and an MSc in computer science from the University of Maryland, College Park.
                                Subfields of computer science I'm interested in are data science, machine learning,
                                natural language processing, audio, mobile, and web. I'm excited about using software and smart algorithms to build tools
                                that make our lives easier and more enjoyable. In my spare time I love playing drums, guitar, and piano;
                                recording and mixing music; and singing in the shower (who doesn't??).
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
